import { mapActions } from 'vuex';
import constant from '~/const';

export default {
  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('reports', ['createReport']),

    async $exportCsv({
      isSelectedAll,
      selection,
      requestData,
      selectionKey,
      type,
    }) {
      let data = {};

      if (isSelectedAll) {
        data = {
          ...requestData,
          offset: 0,
          limit: constant.reports.LIMIT,
          getTotal: false,
        };
      } else {
        data = {
          [selectionKey]: selection,
          offset: 0,
          limit: selection.length,
        };
      }

      this.setGeneralProgress(true);
      try {
        const { fileName } = await this.createReport({
          type,
          data,
        });
        this.confirmAction({
          title: this.$t('common.Request created'),
          text: this.$t('common.Request for report has been created', { fileName }),
          hasCancelButton: false,
        });
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
