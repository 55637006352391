export default [
  {
    caption: 'Select all',
    action: 'selectAll',
  },
  {
    caption: 'Select entire page',
    action: 'selectEntirePage',
  },
  {
    caption: 'Actions',
    action: null,
    children: [
      {
        caption: 'Delete messages',
        action: 'deleteMessages',
      },
      {
        caption: 'Block users',
        action: 'blockUsers',
      },
      {
        caption: 'Export CSV',
        action: 'exportCsv',
      },
    ],
  },
];
