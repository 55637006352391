var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"public-chats"},[_c('div',{staticClass:"d-flex justify-space-between public-chats__filters"},[_c('div',{staticClass:"d-flex mobile-wrap"},[_c('PublicChatsFilters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.PUBLIC_CHAT_MESSAGES,"command":_vm.requestData}})],1)]),_c('div',{staticClass:"public-chats__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"is-selected-all":_vm.isSelectedAll,"data":_vm.publicChats,"config":_vm.tableConfig,"height":"527px","is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","show-select":"","show-actions":""},on:{"update:selection":function($event){_vm.selection=$event},"update:isSelectedAll":function($event){_vm.isSelectedAll=$event},"update:is-selected-all":function($event){_vm.isSelectedAll=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"date",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.utils.getTime(row.dateCreated))+" ")])]}},{key:"username",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"message",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"left":"","max-width":"600px","transition":"fade-transition","z-index":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:{ 'text--disabled': row.isDeleted },on:{"click":function($event){return _vm.copyMessage(row.message)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.message)+" "),_c('div',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(row.originalText))])])]}}],null,true)},[_c('div',[_vm._v(_vm._s(row.message))])])],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","disabled":row.isDeleted},on:{"click":function($event){return _vm.deleteMessage(row.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editMessage(row)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.isDeleted)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('Deleted')))]):(row.editedByAdmin)?_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t('Edited by admin')))]):_c('span',[_vm._v("–")])]}},{key:"admin",fn:function(ref){
var row = ref.row;
return [(row.isDeleted || row.editedByAdmin)?_c('span',[_vm._v(" "+_vm._s(_vm.getAdmin(row.adminId))+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('UsersLock',{attrs:{"type":_vm.constant.users.USER_LOCK_TYPE.GENERAL},on:{"confirm":_vm.block},model:{value:(_vm.usersLock.modal),callback:function ($$v) {_vm.$set(_vm.usersLock, "modal", $$v)},expression:"usersLock.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }