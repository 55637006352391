export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Sender',
    },
    cell: {
      type: 'slot',
      name: 'sender',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Receiver',
    },
    cell: {
      type: 'slot',
      name: 'reciver',
    },
  },
  {
    width: 40,
    header: {
      type: 'text',
      caption: 'Last message',
    },
    cell: {
      type: 'slot',
      name: 'lastMessage',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
];
