//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    type: { type: Number, required: true },
  },

  data() {
    return {
      comment: '',
      blockTime: '',
    };
  },

  computed: {
    ...mapGetters('login', ['getCurrentAdmin']),
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    hasBlockTimeInput() {
      const { type } = this;
      if (type === constant.users.USER_LOCK_TYPE.GENERAL) {
        return false;
      }
      return true;
    },

    isApplyDisabled() {
      const { comment, blockTime, hasBlockTimeInput } = this;
      if (hasBlockTimeInput) {
        return !(comment && blockTime);
      }
      return !comment;
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.comment = `${this.$t('Blocked by')} ${this.getCurrentAdmin.email}`;
        this.blockTime = '720';
      }
    },
  },

  methods: {
    confirm() {
      const { comment, blockTime } = this;
      this.$emit('confirm', { comment, blockTime });
      this.$emit('input', false);
    },
  },
};
