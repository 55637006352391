//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import PublicChats from '~/views/Chats/PublicChats.vue';
import PrivateChats from '~/views/Chats/PrivateChats.vue';
import ChatOptions from '~/views/Chats/ChatOptions.vue';

export default {
  components: {
    PublicChats,
    PrivateChats,
    ChatOptions,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.getPublicChats(),
      this.loadChatOptions(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('publicChats', ['getPublicChats']),
    ...mapActions('chatOptions', ['loadChatOptions']),
  },
};
