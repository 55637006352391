//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import exportCsvMixin from '~/components/mixins/exportCsv';
import PublicChatsFilters from './PublicChatsFilters.vue';
import User from '~/components/User.vue';
import UsersLock from '~/views/Users/modals/UsersLock.vue';
import GetReport from '~/components/GetReport.vue';
import constant from '~/const';
import tableConfig from './publicChatsTableConfig';
import actionsConfig from './publicChatsActionsConfig';
import utils from '~/utils';

export default {

  components: {
    PublicChatsFilters,
    User,
    UsersLock,
    GetReport,
  },

  mixins: [exportCsvMixin],

  data() {
    return {
      selection: [],
      isSelectedAll: false,
      loader: false,
      usersLock: {
        modal: false,
      },
      tableConfig,
      actionsConfig,
      utils,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('login', ['getAdminById']),
    ...mapGetters('publicChats', ['requestData']),
    ...mapState('publicChats', [
      'publicChats',
      'page',
      'pages',
      'sort',
    ]),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('publicChats', {
      getPublicChatsAction: 'getPublicChats',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      deleteMessagesAction: 'deleteMessages',
      editChatMessageAction: 'editChatMessage',
    }),
    ...mapActions('users', {
      usersBlockAction: 'usersBlock',
    }),

    async getPublicChats(data = {}) {
      this.loader = true;
      await this.getPublicChatsAction(data);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
      }

      this.getPublicChats();
    },

    applySort(data) {
      this.setSortAction(data);
      this.getPublicChats();
    },

    setPage(value) {
      this.setPageAction(value);
      const extendedRequestData = {
        getTotal: false,
      };
      this.getPublicChats(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    async copyMessage(message) {
      await navigator.clipboard.writeText(message);
      this.setSuccessNotification('Message copied to clipboard');
    },

    deleteMessage(id) {
      this.confirmAction({
        text: 'Are you sure you want to delete message?',
        callback: async () => {
          this.loader = true;
          this.setGeneralProgress(true);
          await this.deleteMessagesAction([id]);
          await this.getPublicChatsAction();
          this.loader = false;
          this.setGeneralProgress(false);
          this.setSuccessNotification('Message deleted');
        },
      });
    },

    editMessage(row) {
      const { id, message } = row;
      this.confirmAction({
        text: 'Edit message',
        hasPrompt: true,
        promptRequired: true,
        promptLabel: 'Message',
        promptValue: message,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          await this.editChatMessageAction({
            messageId: id,
            message: prompt,
          });
          await this.getPublicChatsAction();
          this.setGeneralProgress(false);
          this.setSuccessNotification('Message edited');
        },
      });
    },

    getAdmin(id) {
      const admin = this.getAdminById(id);
      if (admin) {
        return admin.email;
      }
      return this.$t('Unknown admin');
    },

    selectAll() {
      this.isSelectedAll = true;
    },

    selectEntirePage() {
      const { publicChats } = this;
      this.selection = publicChats.map((e) => e.id);
    },

    deleteMessages() {
      const { selection, isSelectedAll } = this;
      if (!isSelectedAll) {
        this.confirmAction({
          text: 'Are you sure you want to delete selected messages?',
          callback: async () => {
            this.loader = true;
            this.setGeneralProgress(true);
            await this.deleteMessagesAction(selection);
            await this.getPublicChatsAction();
            this.loader = false;
            this.setGeneralProgress(false);
            this.setSuccessNotification('Messages deleted');
          },
        });
      } else {
        this.setErrorNotification('Action is impossible');
      }
    },

    blockUsers() {
      const { isSelectedAll } = this;
      if (!isSelectedAll) {
        this.usersLock.modal = true;
      } else {
        this.setErrorNotification('Action is impossible');
      }
    },

    async block({ comment }) {
      const { publicChats, selection } = this;
      const userIds = publicChats
        .filter((e) => selection.includes(e.id))
        .map((e) => e.userId);

      this.setGeneralProgress(true);
      try {
        await this.usersBlockAction({
          userIds,
          comment,
        });
        this.setSuccessNotification('Users locked');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    exportCsv() {
      const { isSelectedAll, selection, requestData } = this;
      this.$exportCsv({
        isSelectedAll,
        selection,
        requestData,
        selectionKey: 'idList',
        type: constant.reports.REPORT_TYPE.PUBLIC_CHAT_MESSAGES,
      });
    },
  },
};
