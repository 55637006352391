//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      model: {
        groupCurrencyId: 0,
        groupMinBalance: 0,
        privateCurrencyId: 0,
        privateMinBalance: 0,
        publicCurrencyId: 0,
        publicMinBalance: 0,
      },
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('currencies', ['currencies']),
    ...mapState('chatOptions', ['options']),

    isDisabled() {
      const { loader, generalLoader } = this;
      return loader || generalLoader;
    },
  },

  watch: {
    options(data) {
      this.model = data;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('chatOptions', ['updateChatOptions']),

    async apply() {
      const { model } = this;

      this.loader = true;
      this.setGeneralProgress(true);
      try {
        await this.updateChatOptions(model);
        this.setSuccessNotification('Changes saved');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.loader = false;
      this.setGeneralProgress(false);
    },
  },
};
